// needed for positioning by bootstrap dropdowns, popover and tooltip module
// eslint-disable-next-line import/no-unassigned-import
import '@popperjs/core';

import { SiteHelper } from './modules/sitehelper';
import { SuggestController } from './modules/solr-suggest';
import { SearchController } from './modules/solr-ajaxified';
import { FavoritesHandler } from './modules/favorites';

import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader';
import { GoogleMapsController } from './modules/googlemaps';

// eslint-disable-next-line import/no-unassigned-import, @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap';

interface UsercentricsDataProcessingServices {
    googleMaps: boolean,
    youtubeVideo: boolean
}

declare global {
    interface Window {
        siteHelper: SiteHelper,
        favoritesHandler: FavoritesHandler,
        searchController: SearchController,
        googleMapsController: GoogleMapsController,
        googleMapsLoader: GoogleMapsLoader,
        isInitialized: boolean,
        dataLayer: object[],
        ucDps: UsercentricsDataProcessingServices,
        sha256Hash:(value: string) => Promise<string>
    }
}

window.bootstrap = bootstrap;
window.isInitialized = false;

window.sha256Hash = async function sha256Hash (value: string): Promise<string> {
    const data = new TextEncoder().encode(value);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray
        .map((bytes) => bytes.toString(16).padStart(2, '0'))
        .join('');
};

const labels:L10nList = {
    de: {
        searchUrl: '/suchen/',
        suggestionsResultTitle: 'Suchvorschläge',
        documentsResultTitle: 'Themen & Angebote'
    },
    fr: {
        searchUrl: '/fr/recherche/',
        suggestionsResultTitle: 'Suggestion de recherche',
        documentsResultTitle: 'Thèmes et Offres'
    }
};

async function fastbookingLoader (lang: string): Promise<void> {
    const fastbookingForms = document.querySelectorAll('.tx-knfastbooking form');
    if (fastbookingForms.length > 0) {
        const module = await import(/* webpackChunkName: "fastbooking" */ './modules/fastbooking');
        [].forEach.call(fastbookingForms, function (formElem) {
            module.fastbookingComponent().init(formElem, { }, lang);
        });
    }
}

async function lightBoxLoader (): Promise<void> {
    const lightBoxGalleries = document.querySelectorAll('.light-gallery');
    if (lightBoxGalleries.length > 0) {
        await import(/* webpackChunkName: "lightbox" */ './modules/lightbox');
    }
}

async function splideLoader (): Promise<void> {
    const splideElems = document.getElementsByClassName('splide');
    if (splideElems.length > 0) {
        await import(/* webpackChunkName: "splide" */ './modules/splide');
    }
}

function workOnDom (): void {
    window.siteHelper = new SiteHelper();
    window.siteHelper.handleAlertBar('alertBar', 'alertBarCloseLink');
    window.siteHelper.handleSearchBarFocus('collapseSearchBar', 'headerSearchInput');
    const lang = window.siteHelper.getLanguage();

    window.favoritesHandler = new FavoritesHandler();

    // dynamic modules
    fastbookingLoader(lang).then(() => null).catch(() => null);
    lightBoxLoader().then(() => null).catch(() => null);
    // no support for YT videos added, not waiting for Usercentrics consent_status
    splideLoader().then(() => null).catch(() => null);

    // search suggest
    const searchElems = document.getElementsByClassName('tx-solr-search-form');
    [].forEach.call(searchElems, function (el) {
        // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
        const sc = new SuggestController(el, labels[lang].searchUrl,
            {
                suggestionsResultTitle: labels[lang].suggestionsResultTitle,
                documentsResultTitle: labels[lang].documentsResultTitle
            });
    });

    // empty search handling
    const searchForms = document.getElementsByClassName('search-form');
    [].forEach.call(searchForms, function (el) {
        const formElem = <HTMLFormElement>el;
        if ( formElem ) {
            formElem.addEventListener('submit',function(event) {
                const searchInput = <HTMLInputElement>this.querySelector('.js-solr-q');
                if (searchInput && searchInput.value === '') {
                    event.preventDefault();
                    const searchUrl = this.getAttribute('action');
                    if (searchUrl) {
                        window.location.href = searchUrl;
                    }
                }
            });
        }
    });

    // search ajaxified
    window.searchController = new SearchController(lang);

    // google maps
    window.googleMapsLoader = new GoogleMapsLoader({
        apiKey: 'AIzaSyA-bvslH68b9GLtZxapADbO0KK1O4ydvGc',
        language: lang,
        region: 'CH'
    });
    window.googleMapsController = new GoogleMapsController();
    // settings for manta
    window.googleMapsController.setMarkerImage('/typo3conf/ext/manta/Resources/Public/img/icons/map_marker.png');
    window.googleMapsController.setMarkerIcon('iconmanta', '\ue90b', '#000000', '36px');

    // show more items
    const showMoreItemsTriggers = document.querySelectorAll('.js-showMoreItems');
    if (showMoreItemsTriggers.length) {
        showMoreItemsTriggers.forEach((elem) => {
            elem.addEventListener('click', (event) => {
                if (event.currentTarget) {
                    const targetElem = <HTMLElement>event.currentTarget;
                    if (targetElem.parentElement) {
                        const hiddenItems = targetElem.parentElement.querySelectorAll('.item-hidden');
                        if (hiddenItems.length) {
                            hiddenItems.forEach((hiddenElem) => {
                                hiddenElem.classList.remove('item-hidden');
                            });
                            targetElem.remove();
                        }
                    }
                }
            });
        });
    }

    const initializedEvent = new CustomEvent('initialized', {});
    document.dispatchEvent(initializedEvent);
    window.isInitialized = true;
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => workOnDom());
} else {
    workOnDom();
}

// usercentrics consent management
//
window.ucDps = {
    googleMaps: false,
    youtubeVideo: false
};
window.addEventListener('onConsentStatusChange', (event: Event) => {
    const customEvent = <CustomEvent>event;

    const dpsGoogleMaps = 'Google Maps';
    const dpsYoutubeVideo = 'YouTube Video';

    let consentBlockedMsg = 'Der Inhalt wurde aufgrund der gewählten <a onclick="UC_UI.showSecondLayer();">Datenschutzeinstellungen</a> blockiert.';
    if (document.documentElement.lang.substring(0, 2) === 'fr') {
        consentBlockedMsg = 'Le contenu a été bloqué en raison des <a onclick="UC_UI.showSecondLayer();">paramètres de confidentialité</a> choisis.';
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (customEvent.detail.event && customEvent.detail.event === 'consent_status') {
        if (dpsGoogleMaps in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.googleMaps = !!customEvent.detail[dpsGoogleMaps];
        }
        // enable gmaps
        const gmapIframes = document.querySelectorAll('iframe.gmap');
        if (gmapIframes.length) {
            gmapIframes.forEach((elem) => {
                const iFrameElem = <HTMLIFrameElement>elem;
                if (window.ucDps.googleMaps) {
                    if (iFrameElem.dataset.src) {
                        iFrameElem.src = iFrameElem.dataset.src;
                        if (iFrameElem.parentElement) {
                            const noConsentInfo = iFrameElem.parentElement.querySelector('.no-consent-info');
                            if (noConsentInfo) {
                                noConsentInfo.remove();
                            }
                            iFrameElem.classList.remove('no-consent');
                        }
                    }
                } else {
                    iFrameElem.src = '';

                    if (iFrameElem.parentElement) {
                        const noConsentInfo = document.createElement('div');
                        noConsentInfo.innerHTML = consentBlockedMsg;
                        noConsentInfo.className = 'no-consent-info';
                        iFrameElem.parentElement.append(noConsentInfo);
                        iFrameElem.parentElement.classList.add('no-consent');
                    }

                }
            });
        }

        if (dpsYoutubeVideo in customEvent.detail) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            window.ucDps.youtubeVideo = !!customEvent.detail[dpsYoutubeVideo];
        }
        // enable YT iframes
        const ytIframes = document.querySelectorAll('iframe.yt-video');
        if (ytIframes.length) {
            ytIframes.forEach((elem) => {
                const iFrameElem = <HTMLIFrameElement>elem;
                if (window.ucDps.youtubeVideo) {
                    if (iFrameElem.dataset.src) {
                        iFrameElem.src = iFrameElem.dataset.src;
                        iFrameElem.classList.remove('no-consent');
                    }
                } else {
                    iFrameElem.src = '';
                    iFrameElem.classList.add('no-consent');
                }
            });
        }

        if ( !window.ucDps.youtubeVideo ) {
            const ytList = document.querySelectorAll('.youtube-videos');
            if (ytList) {
                [].forEach.call(ytList, function(el){
                    const liElem = <HTMLLIElement>el;
                    if (liElem && liElem.parentNode) {
                        liElem.parentNode.removeChild(liElem);
                    }
                });
            }

        }

        // may show YT videos, waiting for Usercentrics consent_status
        // splideLoader().then(() => null).catch(() => null);

        // dispatch event
        const consentEvent = new CustomEvent('consentStatusSet', {});
        document.dispatchEvent(consentEvent);
    }
});
